import React from 'react';
import { makeStyles } from '../theme';
import Box from '../core/layout/box';
import RenderInTime from '../core/renderintime';

const styles = makeStyles(
    ({ palette, shape }) => ({
        screen: {
            position: 'fixed',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: palette.grey[50]
        },
        loadingBox: {
            width: 250,
            height: 150,
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: shape.borderRadius,
            backgroundColor: palette.grey[300],
            boxShadow: `0 0 5px 1px ${palette.grey[400]}`
        },
        '@keyframes loaderanim': {
            'from': {
                transform: 'rotate(0deg)'
            },
            'to': {
                transform: 'rotate(360deg)'
            }
        },
        loader: {
            width: 40,
            height: 40,
            borderTop: `1px solid ${palette.grey[500]}`,
            borderLeft: `1px solid ${palette.grey[500]}`,
            borderRadius: '50%',
            animation: '$loaderanim 1s infinite linear'
        }
    })
);

const AppLoadingScreen = React.memo(() => {
    const classes = styles();

    return (
        <RenderInTime delay={1000}>
            <Box className={classes.screen}>
				<Box className={classes.loadingBox}>
					<Box className={classes.loader} />
				</Box>
            </Box>
        </RenderInTime>
    );
});

export default AppLoadingScreen;