/*
The following style names should be applied to components where appropriate 
to give wrapping components the ability to easily override styles:

1: after themes have been applied.
2: When the wrapping component does not have direct access to set props.

EG: The DFE DynamicScreen system generates components from JSON. those components 
are generated and theme styles are applied, but then the generated content is deposited 
into a supplied template. The template may want to override styles for 
generated components as well. 
*/

/**
 * Should be applied to any component that is placed inside of a container and is not used for the purpose of layout.
 */
export const Control = 'Control';

/**
 * Should be applied to any component whose purpose is to layout child components.
 */
export const Container = 'Container';

export const Text = 'Text';

export const NavLink = 'NavLink';