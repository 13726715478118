import React from 'react';
import { BrowserRouter, Switch, Route, useHistory } from "react-router-dom";
import mergeOptions from 'merge-options';
import useConfig from '../config';
import { compose } from '../util';
import useScreens  from '../components/screens';

const context = React.createContext({});

export function useRouting() {
	const contextData = React.useContext(context);
	return contextData.routesEnabled;
}

const ScreenSequenceHistory = ({ children }) => {
	const history = useHistory();
	const onRequestScreen = screen => { 
		history.push(`/${screen}`);
	}
	return React.cloneElement(React.Children.only(children), { onRequestScreen })
}

const composeRouteScreens = ({ component = 'DynamicScreen', descriptor, routes, auth, users, ...props }, screens, key, acc) => {
    const Comp = screens[component];
    const { Auth } = screens;

    if(!Comp) return;

    const path = props.path;
    const dynamicProps = { descriptor };

	acc.push(
        compose(
            [Route, { path, exact: true, key: `route-${key}-rand-${Math.round(Math.random() * 10000)}` }],
            ScreenSequenceHistory,
            [Auth, { users, enforce: auth }]
        )(<Comp {...props} {...dynamicProps} routes={routes}/>)
    );

	routes && routes.reduce(
        (acc1, childProps, childKey) => composeRouteScreens(
            mergeOptions(props, childProps, {auth}),
            screens,
            childKey,
            acc1
        ),
        acc
    );

	return acc;
}

const composeEmbedScreen = ({ component = 'DynamicScreen', descriptor, routes, auth, users, data, ...props }, screens) => {
    const Comp = screens[component];
    const { Auth } = screens;
	if(!Comp) return;
	const dynamicProps = { descriptor };
	return compose(
        [Auth, { users, enforce: auth }]
	)(<Comp {...props} {...dynamicProps} routes={routes} previousScreenResults={data}/>);
}

const Routes = React.memo(routeProps => {
    const screens = useScreens();
    const routesFromConf = useConfig('routes') || [];

    if (routeProps.descriptor) {
        return composeEmbedScreen(routeProps, screens);
    } else {
        const routes = routesFromConf.reduce(
            (acc, props, key) => composeRouteScreens(props, screens, key, acc),
            []
        );
    
        return compose(
			[context.Provider, {value:{routesEnabled:true}}],
            BrowserRouter,
            Switch
        )(routes);
    }
});

export default Routes;