import { getConfigResourcePath } from "../util/client";

/*
Compiled Configuration:

Exporting this object will bundle the configuration with the application
leaving it fixed.
*/
//import compiled from './compiledexample';
//export default {value : compiled};

/*
Local Configuration:

Specify a local path to load the configuration from json files.
*/

export default {
	value: getConfigResourcePath('index.json')
};

/*
Remote Configuration:

Specify a remote path to load the configuration from json files.
*/
//export default {value:'http://localhost:7002/index.json'};