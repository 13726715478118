/* 
eslint-disable no-mixed-operators
*/
import React from 'react';
import dlv from 'dlv';
import dset from 'dset';
import mergeOptions from 'merge-options';
import { getPropPathElements, applyFormatters } from './template';

export function deepCopy(obj) {
	return JSON.parse(JSON.stringify(obj));
}

export function callWith() {
	let [f, ...args] = arguments;
	return () => f && f.apply(null, args);
}

export function callAll() {
	const fns = [...arguments];
	return (...args) => fns.forEach( f => {if(f) f.apply(null, args)});
}

export function bindEvent(f) {
	return (event) => f && f.apply(null, [event.target ? event.target.value: event]);
}

export function exists(val){
	return val !== undefined && val !== null;
}

export function isNumber(value) {
	return exists(value) && !isNaN(parseInt(value)) || false;
}

export function isBoolean(value) {
	return value === true || value === false;
}

export function isString(value) {
	return exists(value) && typeof value === 'string';
}

export function isObject(value) {
	return exists(value) && typeof value === 'object';
}

const mergeOptionsConcat = mergeOptions.bind({concatArrays: true});

export function mapProps(props, map) {
	if(!map)return props;

	return Object.keys(map).reduce((acc, mapFrom) => {
		if(mapFrom.indexOf(':') > -1) {
			const [path, value] = mapFrom.split(':');
			const mapTo = map[mapFrom];
			let copyingValue = isObject(props) ? dlv(props, path): props;
			const copyingValueString = copyingValue !== undefined ? copyingValue.toString() : 'null';
			if(copyingValueString === value) acc = mergeOptionsConcat(acc, mapTo);
		} else { 
			const mapToIsdata = isObject(map[mapFrom]);
			const {name:mapToLookup, formatters} = getPropPathElements(map[mapFrom]);
			const mapTo = mapToLookup || props[mapFrom] && mapFrom || null;
			if(!mapTo) return acc;	

			const isSpread = mapTo === '...' || mapToIsdata;
			const value = applyFormatters(mapFrom === '...' ? {...props} : mapToIsdata ? map[mapFrom] : dlv(props, mapFrom) || dlv(acc, mapFrom), formatters);
			
			if(value !== undefined && value !== null) {
				if (isSpread) {
					acc = {...acc, ...value};
				} else {
					dset(acc, mapTo, value);
				}
			};
		}
		return acc;
	}, {});
}

/**
 * This function implements String.matchAll(pattern), which doesn't work on Safari.
 * @param {String} text The text to search.
 * @param {String} pattern The regex pattern to search the string with.
 * @returns {Array} an array of all the regex matches in the string.
 */
export function matchAll(text, pattern) {
	return (text.match(new RegExp(pattern, 'g'))||[]).reduce((acc) => {
		const textslice = text.slice(acc.i);
		const findmatch = textslice.match(new RegExp(pattern));
		const newindex = acc.i + findmatch.index + findmatch[0].length;
		acc.i = newindex;
		const result  = [...findmatch];
		result.index = acc.i + findmatch.index;
		result.input = text;
		result.groups = findmatch.groups;
		acc.r.push(result);
		return acc;
	}, {i:0, r:[]}).r;
}

/**
 * Applies wrapper components to a supplied component instance.
 * 
 * Supply as many wrapper components as you need as arguments to the function.
 * The first specified wrapper will be the last to wrap the component, and the last supplied wrapper will be the first.
 * Wrappers may be supplied as an array in which index 0 is the wrapper, and index 1 is a props object for that wrapper.
 * The function returns a function that allows you to supply the component to be wrapped:
 * 
 * @example
	const wrappedComponent = compose(
		OutermostWrapper,
		[WrapperWithProps, {propForWrapper:'value'}],
		InnermostWrapper
	)(<div><div>);
 *  
 */
export function compose(){
	return children => [...arguments].reverse().reduce((c, item) => {
		if(!item) return c; // We'll skip any items that are falsy. Allows for easy optional wraps.
		const isArray = Array.isArray(item);
		const Wrapper = isArray ? item[0] : item;
		const props = isArray ? item[1] : {};
		return (<Wrapper {...props}>{c}</Wrapper>);
	}, children);
}

/**
 * Find and return the first matching item in the array using the find funciton.
 * @param {Array} arr The array to search.
 * @param {Function} findFn The function that inspects each iteration.
 */
export function first(arr, findFn) {
	if(!arr || !Array.isArray(arr)) return;
	const len = arr.length;
	if(!findFn && len) return arr[0];
	for(let i = 0; i < len; i++) {
		const item = arr[i];
		const test = findFn(item, i, arr);
		if(test) return item;
	}
}

/**
 * Use this hook to make a stateful value available at a future time specified 
 * in milliseconds.
 * 
 * @param {*} value The stateful value to set.
 * @param {Number} delay the amount of milliseconds to wait before making the value available.
 */
export function useLater(value, delay) {
	const [delayedValue, setDelayedValue] = React.useState();
	React.useEffect(() => {
		if(!delayedValue) {
			const t = setTimeout(() => setDelayedValue(value), delay);
			return () => clearTimeout(t);
		}
	}, []); //eslint-disable-line react-hooks/exhaustive-deps
	return [delayedValue];
}

/**
 * Strips all query arguments of the location for the current page and transforms them into an object.
 */
export function locationSearch() {
	const search = window.location.search;
	return search.substring(1).split("&").reduce(function(result, value) {
	  let parts = value.split('=');
	  if (parts[0]) result[decodeURIComponent(parts[0])] = decodeURIComponent(parts[1]);
	  return result;
	}, {});
}

export function concatUrl(url, base = '') {
	if(url.indexOf('://') > -1) return url;
	return `${base.replace(/\/+$/, '')}/${url.replace(/^\//, '')}`;
}

/**
 * Returns the arguments submitted via the page query string as an object.
 */
export function getQueryArgs() {
	if(!window || !window.location) return {};
	const queryArgs = new URLSearchParams(window.location.search);
	return [...queryArgs.keys()].reduce((acc, key) => {
		acc[key] = queryArgs.get(key);
		return acc;
	}, {});
}

/**
 * Applies data to the HTML host page.
 */
export function applyPageMeta({title} = {}) {
	if(document) document.title = title;
}

/**
 * Creates global variables based on `data`.
 * Injects `script tag(s)` based on `tag`.
 */
export function applyScriptInjection(scripts) {
	// We want to make sure `scripts` is an Object because we want to use its `keys` as an `id` for the `script tag` in order to avoid any duplicate script tag injections.
	// This is applicable only when user is navigating sequence screens. For URL to URL navigation, it's always created as new.
	if (isObject(scripts)) {
		Object.keys(scripts).forEach(id => {
			const { enabled, tag, data } = scripts[id];

			if (enabled) {
				// We want to make sure `data` is an Object because we want its `keys` to be used as `window[key]`.
				// Having `data` as object helps to define global variables as `window[key] = value`.
				if (isObject(data)) {
					Object.keys(data).forEach(k => {
						window[k] = data[k];
					})
				}

				if (isObject(tag) && !exists(document.getElementById(id))) {
					const { src, ...restAttrs } = tag;
					const attrs = {
						...restAttrs,
						id,
						src: isObject(src) ? (src[process.env.REACT_APP_SCRIPT_ENV] || src[process.env.NODE_ENV])  : src
					};

					const s = document.createElement('script');
					Object.keys(attrs).forEach(k => s.setAttribute(k, attrs[k]));
					document.head.appendChild(s);
				}
			}
		});
	}
}

export function safeStringify(val) {
	try {
		const r = JSON.stringify(val);
		return r === 'null' ? null : r;
	} catch(e) {}
	return null;
}

export function safeParse(val) {
	try {
		return JSON.parse(val);
	} catch(e) {
		return null;
	}
}

export function addSearchParams(copyTo, copyFrom) {
	const r = copyTo;
	if(copyFrom && copyFrom.entries) {
		[...copyFrom.entries()].forEach(([name, value]) => r.set(name, value));
	}
	return r;
}

export function cleanUrl(url) {
	//Removes any unnecessary duplicate instances of '/' character if they were concantenated by mistake:
	return url.replace(/(\/\/)+\/?/g, '/');
}

/**
 * This function is used to apply data in the supplied object to the window object.
 * Array values at the top-level will merge rather than override.
 */
export function applyWindowProps(propsToApply) {	
	if(propsToApply) {
		Object.keys(propsToApply).forEach(k => {
			if(Array.isArray(window[k])) {
				//push based:
				propsToApply[k].forEach(k2 => window[k].push(k2));
			} else {
				window[k] = propsToApply[k];
			}
		});
	}
}