import "./wdyr.js";
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { register as registerSW } from './service-worker';
import { handleServiceWorkerOnUpdate } from './service-worker/util';
import './index.css';

export function render(selector, props) {
	if(!selector) console.error('The DynamicFE requires a container element id to render into, eg: DynamicFE.render("#root")');

	ReactDOM.render(<App {...props} />, document.querySelector(selector));
}

// As we support embeddable app now, moved this out of `render` function as both run on `window.onload`.
// Register Service worker only for native app. For embed js, this feature is disabled due to CORS issue of Servie worker.
if(!window.dfeResourceUrl) {
	registerSW({
		onUpdate: handleServiceWorkerOnUpdate
	});
}