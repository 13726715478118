/** Backing store that persists data into webStorage (eg localStorage, sessionStorage)
 *	@name webStorage
 *	@memberof adapters
 *	@namespace {function}
 */
let createWebStorageAdapter = (webStorage) => ({ storageKey }) => ({
	/** Test to see if webStorage is supported.
	*	@private
	*/
	test() {
		try {
			webStorage.setItem('__', 1);
			webStorage.removeItem('__');
			return true;
		}
		catch (e) {}
	},

	/** Load and parse JSON data from webStorage.
	*	@private
	*/
	load() {
		let data;
		try {
			data = JSON.parse(webStorage.getItem(storageKey));
		} catch (err) {}
		return data;
	},

	/** Serialize data to JSON and store it in webStorage.
	 *	@private
	 */
	save(data) {
		webStorage.setItem(storageKey, JSON.stringify(data));
	}
});

/** Backing store that persists data into localStorage
 *	@name localStorage
 *	@memberof adapters
 *	@namespace {function}
 */
export let localStorageAdapter = createWebStorageAdapter(localStorage);

/** Backing store that persists data into sessionStorage
 *	@name sessionStorage
 *	@memberof adapters
 *	@namespace {function}
 */
export let sessionStorageAdapter = createWebStorageAdapter(sessionStorage);
