import React from 'react';
import dlv from 'dlv';
import { isString, locationSearch, concatUrl } from '../util';
import configinit from './configinit';

const context = React.createContext({});

const ConfigProvider = React.memo(
	({baseUrl, ...props}) => {
		const { value } = configinit;
		const [config, setConfig] = React.useState();

		React.useEffect(() => {
			if(isString(value)) {
				fetch(concatUrl(value,baseUrl), {method: 'GET', headers:{Accept:'application/json'}})
				.then(response => response.json())
				.then(d => {
					setConfig({baseUrl, ...d});
				});
			} else {
				setConfig({baseUrl, ...value});
			}
		}, []); //eslint-disable-line react-hooks/exhaustive-deps

		return config ? <context.Provider {...props} value={{ ...locationSearch(), ...config }} /> : null;
	}
);

export { ConfigProvider };

export default function useConfig(prop, props) {
	const contextData = React.useContext(context);
	return prop ? dlv(props, prop, dlv(contextData, prop, {})) : contextData || {};
}