import { scope } from '../i18n';
import dlv from 'dlv';
import dset from 'dset';
import dbee from '../dbee';
import { agent, contains, versionAtLeast } from '../util/browser';

export const i18n = 'services';
/**
 * Actual IDM Service implementation will be implemented here.
 * They are provided to the application components via context: useService('serviceName').
 * 
 * Each Service is expressed as a function matching this signature:
 * (data, { setSuspense, setResult, setError}, previousResults) => {};
 * 
 * data - The data object being passed to the service as arguments.
 * setSuspense(boolean) - Tells the UI where or not it should show loading/async state.
 * setResult(object) - Pass result data back to the UI and complete the process.
 * setError(object) - Pass error data back to the UI and complete the process.
 * previousResults -	An array that contains any previous results returned from other services that may relate to this one.
 * 						Used to access results from previous screens in a sequence.
 * 
 */
function init({ config, i18n } = {}) {

	const auth_token = '1234';
	const demoCode = '1234';

	const demoUser = {
		userId:'demo',
		accountId:'1234',
		phone:'6137284010',
		postalCode:'90210',
		city:"Ottawa",
		state:"On",
		address:"221 Bel-Air",
		isPrimaryAccount:true,
		hint:{
			email:"d***@example.com",
			phone:"6********0"
		}
	}

	const demoSecurityQuestion = {
		question:i18n && i18n.t('demoSecurityQuestion.question'),
		answer:i18n && i18n.t('demoSecurityQuestion.answer')
	}

	const suggestedquestions = [
		i18n && i18n.t('suggestedQuestions.q1'),
		i18n && i18n.t('suggestedQuestions.q2'),
		i18n && i18n.t('suggestedQuestions.q3'),
	]
	
	const demoParentalControls = {
		parentalControlOptions:{
			tvRatings:["TV-Y", "TV-Y7", "TV-G", "TV-PG", "TV-14", "TV-MA"],
			movieRatings:["G", "PG", "PG-13", "R", "NC-17"],
			trailerRatings:["Red", "Yellow", "Green"],
		},
		userParentalControls:{
			tvRatings:"TV-14",
			movieRatings:"PG-13",
			trailerRatings:"Yellow",
			unrated:true
		}
	};

	/*
	const GetUsers = (req, { setResult, setError }) => {
		setResult({users:dlv(config, 'demo.users', [])});
	}*/

	const api_key = "rps87945mbqb86ew635443rm";

	
	const getUserDetail = (user) => {
		return fetch(
			`https://cloudid.syn-api.com/idbridge/orgs/cableco_rt/accounts/${user.accountId}/users/${user.userId}`,
			{
				method:'GET',
				headers:{
					"X-Api-Key": api_key,
					Accept:'application/json',
					['Content-Type']:'application/json', //eslint-disable-line no-useless-computed-key
				}
			}
		)
		.then(r => r.status === 200 ? r.json() : null)
		.then(({ firstName, lastName, ...r}) => {
			return {...r, firstname:firstName, lastname:lastName, postalCode:r.accountInfo.postalCode, isPrimaryAccount: user.userId === user.accountId}
		});
	}

	const Base64Encode = (req, { setResult, setError, setSuspense }) => {
		const unencoded = JSON.stringify(req);
		setResult({unencoded, encoded:btoa(unencoded)});
	}

	//ID Bridge version
	const GetUsers = (req, { setResult, setError, setSuspense }) => {


		const db = dbee('fauxLogin');
		const userId = db.get('userId') || 'tmtek';

		setSuspense(true);
		fetch(
			`https://cloudid.syn-api.com/idbridge/orgs/cableco_rt/accounts/${userId}`,
			{
				method:'GET',
				headers:{
					"X-Api-Key": api_key,
					Accept:'application/json',
					['Content-Type']:'application/json', //eslint-disable-line no-useless-computed-key
				}
			}
		)
		.then(r => r.status === 200 ? r.json() : null)
		.then(result => {
			if(result) {		
				return [result].reduce((acc, oUser) => {
					const subUsers = Object.keys(oUser.userProperties).reduce((acc,k) => {
						if(k !== oUser.accountId) acc.push({
							userId:k,
							accountId:oUser.accountId,
						})
						return acc;
					}, []);

					const pOwner = {
						userId:oUser.accountId,
						accountId:oUser.accountId,
						postalCode:oUser.postalCode
					}
					acc.push(pOwner);
					subUsers.forEach(u => acc.push(u));
					return acc;
				}, []);
			} else{
				return [];
			}
		})
		.then(users => {
			return Promise.all(users.map(u => getUserDetail(u)))
		})
		.then(r => setResult({users:r}))
		.finally(() => setSuspense(false));
	}

	const DeleteUser = (req, { setResult, setError }) => {
		dset(config, 'demo.users', dlv(config, 'demo.users', []).filter(u => u.userId !== req.userId));
		setResult({});
	}

	const VerifyAccountInfo = (req, { setResult, setError }) => {
		let t = scope(i18n,'VerifyAccountInfo');
		let accountId = req.accountnumber;
		let phone = req.phonenumber;
		let postalCode = req.postalcode;

		let foundUser = dlv(config, 'demo.users', [])
			.filter(user => accountId === user.accountId && phone === user.phone && postalCode === user.postalCode);

		if(foundUser && foundUser.length) {
			setResult({username:foundUser[0].userId,user:foundUser[0]});
		} else {
			setError({general:t('failedlookup')});
		}
	}

	const Authenticate = (req, { setResult }) => {
		setResult({accessToken:auth_token});
	}

	const VerifyUsername = (req, { setResult, setError }) => {
		let t = scope(i18n,'VerifyUsername');
		let userId = req.username;

		let foundUser = dlv(config, 'demo.users', [])
			.filter(user => userId === user.userId);

		if(foundUser && foundUser.length) {
			setResult({username:foundUser[0].userId, user:foundUser[0]});
		} else {
			setError({general:t('notfound')});
		}
	}

	const IssueRecoverCode = (req, { setResult }) => {
		setResult({});
	}

	const VerifyRecoverCode = (req, { setResult, setError }) => {
		let t = scope(i18n,'VerifyRecoverCode');
		if(req.code === demoCode) {
			setResult({user:{...demoUser, recoveryemail:req.email}});
		} else {
			setError({general:t('invalidcode')});
		}
	}

	const LookupSecurityQuestion = (req, { setResult }) => {
		setResult({question:demoSecurityQuestion.question});
	}

	const VerifySecurityQuestion = (req, { setResult, setError }) => {
		let t = scope(i18n,'VerifySecurityQuestion');
		let answer = req.answer;
		if(answer === demoSecurityQuestion.answer) {
			setResult({});
		} else {
			setError({general:t('wronganswer')});
		}
	}

	const NewPassword = (req, { setResult }) => {
		setResult({});
	}

	const RegisterPassword = (req, { setResult, setError }) => {	
		let t = scope(i18n,'RegisterPassword');
		let userId = req.username;

		let foundUser = dlv(config, 'demo.users', [])
			.filter(user => userId === user.userId);

		if(foundUser && foundUser.length) {
			setResult({user:foundUser});
		} else {
			setError({username:t('usernamenotavailable')});
		}
	}

	const AddProfileInfo = (req, { setResult }) => {	
		const email = req.email;
		setResult({user:{email}});
	}

	const GetParentalControls = (req, { setResult }) => {	
		setResult(demoParentalControls);
	}

	const SetParentalControls = (req, { setResult }) => {	
		setResult({});
	}

	const GetSecretQuestions = (req, { setResult }) => {	
		setResult({suggestedquestions});
	}

	const SetSecretQuestion = (req, { setResult }) => {	
		setResult({});
	}

	const ProvisionEmailService = (req, { setResult }) => {	
		setResult({});
	}

	const UpdateEmailRecovery = (req, { setResult }) => {	
		setResult({recoveryemail:req.email});
	}

	const UpdatePhoneRecovery = (req, { setResult }) => {	
		setResult({recoveryphonenumber:req.phonenumber});
	}

	/*
	This service is used to test the user agent to see if the current browser is able 
	to launch a native app with state data via a Kochava link.
	*/
	const CanAppLinkWithState = (req, { setResult }) => {
		const challenge = agent();	
		setResult({
			canAppLinkWithState:{
				allowed:
					//iOS 14+ does not allow Kochava links with state.
					!challenge(contains('os.name', 'iOS'), versionAtLeast('os.version', '14'))
			}
		});
	}

	return {
		VerifyAccountInfo,
		Authenticate,
		VerifyUsername,
		IssueRecoverCode,
		VerifyRecoverCode,
		LookupSecurityQuestion,
		VerifySecurityQuestion,
		NewPassword,
		RegisterPassword,
		AddProfileInfo,
		GetParentalControls,
		SetParentalControls,
		GetSecretQuestions,
		SetSecretQuestion,
		ProvisionEmailService,
		UpdateEmailRecovery,
		UpdatePhoneRecovery,
		GetUsers,
		DeleteUser,
		Base64Encode,
		CanAppLinkWithState
	};
}

export default {i18n, init};