import React from 'react';
import i18n from "i18next";
import dlv from 'dlv';
import dset from 'dset';
import { initReactI18next, useTranslation as useTranslationOrig } from "react-i18next";
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import useConfig from '../config';
import { isString, isObject, matchAll, concatUrl } from '../util';
import { getConfigResourcePath } from '../util/client';

const defaultConfig = {
	debug:false,
	whitelist: ["en"],
	ns: ["common"],
	defaultNS: "common",
	fallbackNS : "common",
	lowerCaseLng:true,
	fallbackLng : "en",
	keySeparator: ".",
	interpolation: { 
		"escapeValue": false
	}
}

const i18nInst = i18n.use(initReactI18next);
export default i18nInst;

export function scope(t, key ='') {
	if(!t) return;
	return (k, d) => t.t([key, k].filter(v => !!v).join('.'), d);
}

export function getLocalizedText (t, text) {
	if(!isString(text))return text;
	const matches = [...matchAll(text, '\\$t\\((.+?)\\)')];
	if(!matches ? !matches.length : !t) return text;
	text = matches.reduce((acc, [match, id]) => {
		/*
		Basic {{var}} templating is filled in by the screen template system rather than the i18n vars system.
		We will leave it in, but require an extra brace set.
		*/
		const tval = t(id, {interpolation:{prefix:"{{{",suffix:"}}}"}});
		return  acc.replace(match, tval);
	}, text);
	return text;
}

export function localizeObject (t, obj) {
	if(!t || !isObject(obj)) return obj;
	const localize = v =>  isString(v) ? getLocalizedText(t, v) : localizeObject(t, v);
	if(Array.isArray(obj))return obj.map(localize);
	return Object.keys(obj).reduce((acc,key) => {
		acc[key] = localize(obj[key]);
		return acc;
	}, {});
}

export const LocalizationContext = React.createContext();

export function LocalizationProvider({children, i18n, baseUrl}) {
	const i18nconfig = useConfig('i18n') || {};
	const compiled = {...defaultConfig, ...i18nconfig};
	dset(compiled, 'backend.loadPath', concatUrl(getConfigResourcePath(dlv(compiled,'backend.loadPath')), baseUrl));
	
	React.useEffect(() => { 
		i18n
			.use(Backend)
			.use(LanguageDetector)
			.init(compiled);
	}, []); //eslint-disable-line react-hooks/exhaustive-deps

	return <LocalizationContext.Provider value={i18n}>{children}</LocalizationContext.Provider>;
}

export function useTranslation(ns, options) {
	return useTranslationOrig(ns, {i18n:i18nInst, ...options});
}

export function getLanguage() {
	return i18nInst.language;
}

