import { isString } from './';

/*
Formatters:
	These formatting functions are used by the templating/data-binding 
	system to reformat data.

They will be used in the templating system the using ?:

{{var?formatter}}
*/

export function lowercase(value) {
	return isString(value) ? value.toLowerCase() : value;
}

export function uppercase(value) {
	return isString(value) ? value.toUpperCase() : value;
}

export function delimit(value) {
	return Array.isArray(value) ? value.join(','): value;
}

export function base64encode(value) {
	return btoa(value);
}

export function base64decode(value) {
	return atob(value);
}

export function jsonStringify(value) {
	try{
		return JSON.stringify(value, null, 2);
	} catch(e) {
		return '';
	}
}

export function jsonParse(value) {
	try{
		const v = JSON.parse(value);
		return v;
	} catch(e) {
		return null;
	}
	
}