import UserAgentParser from 'ua-parser-js';
import dlv from 'dlv';

/**
 * USE - https://caniuse.com/
 * 
 * `LATEST_FEATURE_SUPPORTING_BROWSERS` is prepared by referring to the data on https://caniuse.com/ for used features in this project.
 * Determine if (CSS/JS) `feature/rule` (OR its `property value`) that we're going to use workes on browser versions listed under `LATEST_FEATURE_SUPPORTING_BROWSERS`.
 *
 * If any feature you use requires higher browser's version than the ones provided below, bump its version.
 *
 * But keep in mind, below table needs to be reviewed every time new feature is used.
 */
const LATEST_FEATURE_SUPPORTING_BROWSERS = {
    "Edge": 79,
    "Firefox": 79,
    "Chrome": 60,
    "Safari": 11,
    "Opera": 47,
    "Mobile Safari": 11,
    "Android Browser": 81,
    "Opera Mobile": 46,
    "Android Chrome": 84,
    "Android Firefox": 68,
    "Samsung Internet": 12
};

export function isLatestBrowserFeaturesSupported() {
    const ua = (new UserAgentParser()).getResult();
    const { browser, os } = ua;
    let uaKey = browser.name;

    // `Android smart phones` having Chrome/Firefox have different supporting version that their `Desktop` counterparts.
    // So just reformatting the UA lookup Key.
    if (os.name === 'Android') {
        if (browser.name === 'Chrome' || browser.name === 'Firefox') {
            uaKey = `${os.name} ${browser.name}`;
        }
    }

    return LATEST_FEATURE_SUPPORTING_BROWSERS[uaKey] && LATEST_FEATURE_SUPPORTING_BROWSERS[uaKey] <= parseFloat(browser.major, 10);
}


/**
 * This function builds a user agent object and allows you to challenge it 
 * with challenge functions.
 * @param {String} agent a custom userAgent string. If not supplied, the 
 * agent supplied by the browser is used. 
 * @returns A function that may be used to make challenges. (challengeFn...) => true;
 */
export function agent(agent) {
	const ua = (new UserAgentParser(agent)).getResult();
	return function() {
		var r = true;
		for(var i = 0; i < arguments.length; i++) {
			r = arguments[i](ua);
			if(!r)return r;
		}
		return r;
	};
}

/**
 * An agent challenge function used to check if the target value contains the supplied string.
 * @param {String} path the path to the value to test in the agent.
 * @param {String} value the string or regex to check for.
 * @returns true if the target value matches the supplied string or regex. false otherwise.
 */
export function contains(path, value) {
	return agent => dlv(agent,path).match(value) != null;
}

/**
 * An agent challenge function used to test version numbers by way of 
 * equal to or greater than.
 * @param {String} path  The path to the version number to test.
 * @param {String} value The version number that the target must be equal to or greater than.
 * @returns true of the target version number is equal to or greather than the supplied value.
 */
export function versionAtLeast(path, value) {
	return agent => {
		const agentValue = dlv(agent, path);
		if(agentValue) {
			const vAgent = toVersionNumber(agentValue);
			const vDesired = toVersionNumber(value);
			const multipliers = versionMultipliers(vAgent, vDesired);
			const agentVersion = absoluteVersionNumber(vAgent, multipliers);
			const desiredVersion = absoluteVersionNumber(vDesired, multipliers);
			return agentVersion >= desiredVersion;

		} else {
			return false;
		}
	};
}

/**
 * Converts a version string to an object with component numbers.
 * @param {String} str eg: 12.2.123 
 * @returns And object:{major, minor, rev}
 */
function toVersionNumber(str) {
	if(str){
		const [major = 0, minor = 0, rev = 0] = str.split('.').map(v => parseInt(v));
		return {major, minor, rev};
	}
	return null;
}

/**
 * Returns an object containing multipliers for minor and major versions used when 
 * comparing 2 version numbers and compiling each to an absolute number. These 
 * multipliers will ensure that a rev or major number can never be high enough 
 * to cause the superior version to increment.
 */
function versionMultipliers(v1, v2) {
	const minor = Math.max((v1.rev || 0), (v2.rev || 0)) + 1;
	const major = (Math.max((v1.minor || 0), (v2.minor || 0)) + 1) * minor;
	return {major, minor};
}

/**
 * Returns an absolute number created from version component numbers. Major and minor 
 * values are multipled by the optional supplied multipliers.
 */
function absoluteVersionNumber(version, {major:majorMultiplier = 100000, minor:minorMultiplier = 1000}) {
	var r = 0;
	if(version.major) r+= (version.major) * majorMultiplier;
	if(version.minor) r+= (version.minor) * minorMultiplier;
	if(version.rev) r+= version.rev;
	return r;
}