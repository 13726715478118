import React from 'react';
import { makeStyles } from '../../theme';

const styles = makeStyles({
	visHidden: {
		visibility: 'hidden'
	}
});

/**
 * Use this component to display it's children only after the time
 * specified as delay has passed (based on value of `trick` parameter).
 * @param `trick` - "display" means either render `children` OR `null`.
 * @param `trick` - "visibility" means always render `children and then, alter its `visibility`.
 */
const RenderInTime = React.memo(
	({ delay, children, trick = 'display' }) => {
		const classes = styles();
		const [allowRender, setAllowRender] = React.useState(!delay);

		React.useEffect(() => {
			if(!delay) return;
			const timeout = setTimeout(() => setAllowRender(true), delay);
			return () => clearTimeout(timeout);
		}, []);//eslint-disable-line react-hooks/exhaustive-deps

		if (trick === "visibility") {
			return (
				<React.Fragment>
					{children.map(c => React.cloneElement(
						React.Children.only(c), { className: !allowRender && classes.visHidden }
					))}
				</React.Fragment>
			);
		}

		return allowRender && <React.Fragment>{children}</React.Fragment>;
	}
);

export default RenderInTime;