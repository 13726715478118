import React from 'react';

export const MOBILE_DEVICE = 'mobile';
export const DESKTOP_DEVICE = 'desktop';

const SMALL_SCREEN_DEVICE_MEDIA_QUERY = 'screen and (max-width: 970px)';

const DeviceVarianceContext = React.createContext(DESKTOP_DEVICE);

export function DeviceVarianceProvider(props) {
    const mql = window.matchMedia(SMALL_SCREEN_DEVICE_MEDIA_QUERY);
    const [smScrnDevice, setSmScrnDevice] = React.useState(mql.matches);

    React.useEffect(() => {
		const listener = e => setSmScrnDevice(e.matches);
		mql.addListener(listener);
		return () => mql.removeListener(listener);
	}, [mql]);

	return <DeviceVarianceContext.Provider {...props} value={smScrnDevice ? MOBILE_DEVICE : DESKTOP_DEVICE} />
}

export default function useDeviceVariance() {
    return React.useContext(DeviceVarianceContext);
}