import { lazy } from 'react';

export default {
	Dashboard: lazy(() => import(
		/* webpackChunkName: "dashboard-screen" */
		'./dashboard'
	)),
	DashboardManageUsers: lazy(() => import(
		/* webpackChunkName: "manageuser-screen" */
		'./dashboard/manage-users'
	)),
	Edit: lazy(() => import(
		/* webpackChunkName: "tmpl-gen-screen" */
		'./edit'
	)),
};