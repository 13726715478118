import { lazy } from 'react';

const templates = {
	Blank: lazy(() => import(
		/* webpackChunkName: "blank-screen" */
		'./blank'
	)),
	SplitInfo: lazy(() => import(
		/* webpackChunkName: "infosplit-template" */
		'./infosplit'
	)),
	LinearInfo: lazy(() => import(
		/* webpackChunkName: "infolinear-template" */
		'./linear/info'
	)),
	LinearCentered: lazy(() => import(
		/* webpackChunkName: "linearcentered-template" */
		'./linear/centered'
	)),

	DashboardPage: lazy(() => import(
		/* webpackChunkName: "dashboard-inner-screen" */
		'./dashboard/dashboardpage'
	))
};


/**
 * This object maintains backwards compatibility 
 * with old template names:
 */
const compat = {
	AuthFlow:templates.SplitInfo,
	Linear:templates.LinearInfo,
	Dialog:templates.LinearCentered
};


export default {
	default:templates.SplitInfo,
	...templates, 
	...compat
};