import React from 'react';
import cx from 'classnames';
import ReactBox from '@material-ui/core/Box';
import * as componentStyling from '../../styling';

export const HORIZONTAL = 'row';
export const VERTICAL = 'column';

const Box = React.memo(
	({type = VERTICAL, className, ...props}) => (
		<ReactBox 
			{...props} 
			display="flex" 
			flexDirection={type}
			className={cx(componentStyling.Container, className)} 
			/>
	)
);

export default Box;