import { isString, matchAll } from './'

function checkIndividualCondition(condition, lookup) {
	if(!condition) return false;
	const [fpath, value] = condition.split(':');
	const reverse = fpath[0] === '!';
	const path = reverse ? fpath.substring(1) : fpath;
	const lookupValue = lookup ? lookup(path) : null;
	const fvalue = isString(value) ? (lookup(value) || value) : value;
	if(fvalue) {
		const valueAsStringString = fvalue !== undefined ? fvalue.toString() : 'null';
		return lookupValue.toString() === valueAsStringString.toString();
	}
	const finalValue = !!lookupValue;
	return reverse ? !finalValue : finalValue;
}

export function checkCondition(condition, lookup) {
	if(!condition) return false;
	const match = matchAll(condition, /(.+?)(?=\|\||\&\&|$)/); //eslint-disable-line no-useless-escape
	const conditions =  match.map(v => {
		const str = v[0];
		const isOr = str.indexOf("||") > -1;
		const isAnd = str.indexOf("&&") > -1;
		const strippedStr = (isOr ? str.split("||")[1] : isAnd ? str.split("&&")[1] : str).trim();
		return {condition:strippedStr, isOr, isAnd};
	});
	let lastEval = true;
	for(let i = 0; i < conditions.length; i++) {
		const c = conditions[i];
		const evalC = checkIndividualCondition(c.condition, lookup);
		if(c.isOr) {
			lastEval = evalC || lastEval;
		} else if (c.isAnd) {
			lastEval = lastEval && evalC;
		} else {
			lastEval = evalC;
		}
		if(i > 0 && !lastEval) return lastEval;
	}
	return lastEval;
}