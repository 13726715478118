import React, {Suspense} from 'react';
import i18n, { LocalizationProvider } from './i18n';
import { compose } from './util';
import ThemeProvider from './components/theme';
import { ServiceProvider } from './service';
import defaultServices from './service/services';
import { ConfigProvider } from './config';
import Routes from './routes';
import useConfig from './config';
import { useTranslation } from './i18n';
import { ScreensProvider } from './components/screens';
import { DeviceVarianceProvider } from './components/core/devicevariance';
import AppLoadingScreen from './components/apploading';
import ReactGA from "react-ga4";

const ServiceProviderSupport = React.memo(
	({i18nNs, children}) => {
		const config = useConfig();
		const i18n = useTranslation(i18nNs);
		if(config?.data?.googleAnalytics){
			ReactGA.initialize(config.data.googleAnalytics);
			ReactGA.send("pageview");
		}
		return React.cloneElement(React.Children.only(children), {config, i18n});
	}
);

const App = React.memo(
	({ baseUrl = window.dfeResourceUrl, ...props }) => compose(
		[ConfigProvider, {baseUrl}],
		[LocalizationProvider, {i18n, baseUrl}],
		[Suspense, {fallback: <AppLoadingScreen />}],
		DeviceVarianceProvider,
		ScreensProvider,
		[ThemeProvider, {baseUrl}],
		[ServiceProviderSupport, {i18nNs:defaultServices.i18n}],
		[ServiceProvider, {services:defaultServices.init}]
	)(<Routes {...props}/>)
);

export default App;