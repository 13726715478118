export function isExternalPath(resourceName) {
	if(!resourceName)return false;
	if(resourceName.indexOf("://") > -1)return true;
	if(resourceName.indexOf("data:") > -1)return true;
	return false;
}

const RESOURCE_TYPE_FOLDER_MAP = {
	CONFIG: 'config',
	IMAGE: 'images',
	THEME: 'theme'
}

const getResourcePath = (resourceName, type) => `/client-assets/${type}/${resourceName}`;

export function getImageResourcePath(resourceName) {
	if(!resourceName)return null;
	if(isExternalPath(resourceName)) return resourceName;
	return getResourcePath(resourceName, RESOURCE_TYPE_FOLDER_MAP.IMAGE);
}

export function getConfigResourcePath(resourceName) {
	return getResourcePath(resourceName, RESOURCE_TYPE_FOLDER_MAP.CONFIG);
}

export function getJssResourcePath(resourceName = 'index.json') {
	return getResourcePath(resourceName, RESOURCE_TYPE_FOLDER_MAP.THEME);
}