/** Backing store that just persists data in-memory.
 *	@name memory
 *	@memberof adapters
 *	@namespace {function}
 */
export default () => /** @lends adapters.memory.prototype */ ({
	/** does nothing
	 *	@private
	 */
	load() {},

	/** does nothing
	 *	@private
	 */
	save() {}
});
