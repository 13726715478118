import React from 'react';
import mergeOptions from 'merge-options';
import { makeStyles, ThemeProvider, createTheme } from '@material-ui/core/styles';
import { concatUrl } from '../../util';
import fetchJss from '../../util/config';
import { getJssResourcePath } from '../../util/client';

/**
 * Use `theme settings` as much as possible instead of hard-coded values.
 * More theme settings on: https://material-ui.com/customization/theming/
 */
// Default theme settings can be provided here which can be then overridden by client specific theme settings.
const DEFAULT_THEME = {
	props: {
		MuiButtonBase: {
			disableRipple: true //Ripple used for select states
		}
	},
	palette: {
		custom: {} // Custom CSS rules that can be added in client specific theme config files.
	},
	typography: {
		fontFamily: 'Roboto, Helvetica, Arial, sans-serif' // Default font family.
	}
};

// A list of default fonts to be used if `theme/index.json` doesn't have any details on `font` usage.
const DEFAULT_FONTS = [
	{ "family": "roboto", "type": ["300", "400", "900"] }
];

// Dynamic Fonts loader helper.
const loadFonts = fonts => {
	const p = fonts.reduce((acc, f) => {
		acc.push(...f.type.map(
			s => import(`@fontsource/${f.family}/${s}.css`) // like `@fontsource/roboto/300.css`
		));
		return acc;
	}, []);

	return Promise.all(p);
};

/**
 * Instantiate this at the root of your project to apply 
 * theme overrides.
 */
const ReactThemeProvider = React.memo(
	({baseUrl, ...props}) => {
		const [theme, setTheme] = React.useState();

		React.useEffect(() => {
			fetchJss(concatUrl(getJssResourcePath(), baseUrl))
			.then(({ fonts = DEFAULT_FONTS, ...themeOverrides }) => (
				loadFonts(fonts).catch(e => {
					console.log(`Fonts could not be loaded completely due to: ${e}`);
				}).then(() => themeOverrides)
			))
			.then(themeOverrides => {
				setTheme(
					mergeOptions(DEFAULT_THEME, themeOverrides)
				)
			})
			.catch(e => {
				console.log(`Falling back to default theming due to: ${e}`);
				setTheme(DEFAULT_THEME);
			});
		}, [baseUrl]);

		return theme ? <ThemeProvider {...props} theme={createTheme(theme)}/>: null
	}
);

export default ReactThemeProvider;

export { makeStyles };