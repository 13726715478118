/* 
eslint-disable no-mixed-operators
*/
import React from 'react'; 
import dlv from 'dlv';
import { mapProps } from '../util';
import { fillTemplate, fillTemplatedObject } from '../util/template';
import { localizeObject, getLanguage } from '../i18n';
import { checkCondition } from '../util/conditional';

const ServiceContext = React.createContext();
const debugMode = false;
const logPrefix = "IDM-FE Service";

const logRequest = (id, fn) => (data = {}, api, previousResults = [], t) => {
	console.log(`${logPrefix} Request:${id}`, data, previousResults);
	return fn(data, api, previousResults, t);
};

const logResult = (id, fn) => (result) => {
	console.log(`${logPrefix} Result:${id}`, result);
	return fn(result);
};

const logError = (id, fn) => (result) => {
	console.log(`${logPrefix} Error:${id}`, result);
	return fn(result);
};
const empty = () => (_, { setResult }) => setResult({});
const result = r => (_, { setResult }) => setResult(r);
const error = e => (_, { setError }) => setError(e);
const suspense = (delay, fn) => (data, api, previousResults, t) => {
	api.setSuspense(true);
	setTimeout(() => {
		fn(data, api, previousResults, t);
		api.setSuspense(false);
	}, delay);
};

function stub({result:dResult, error:dError, suspense:dSuspense, custom}) {
	const fn = custom ? custom : dError ? error(dError) : dResult ? result(dResult) : empty();
	return dSuspense ? suspense(dSuspense, fn) : fn;
}

const remoteCall = (
	{ serviceName, endpoint, method, args, headers, flatResult, flatError, mapRequest, mapResponse, remoteFn},
	{ setSuspense, setResult, setError },
	previousResults, 
	t
) => {
	setSuspense(true);
	const dMethod = fillTemplate(method, key => args && args[key] || inResults(previousResults, key));
	const postBody = dMethod && (dMethod === 'POST' || dMethod === 'PUT') ? {body:JSON.stringify(mapProps(args, mapRequest))} : {};
	const url = fillTemplate(endpoint, key => args && args[key] || inResults(previousResults, key));
	const options = { //eslint-disable-line no-mixed-operators
		method: dMethod || 'GET',
		headers:{
			Accept:'application/json',
			['Content-Type']:'application/json', //eslint-disable-line no-useless-computed-key
			['Accept-Language']:getLanguage(), //eslint-disable-line no-useless-computed-key
			...(headers ? fillTemplatedObject(headers, key => args && args[key] || inResults(previousResults, key)) : {})
		},
		...postBody
	};

	const postProcessResponse = response => {
		const r = localizeObject(t && t.t, mapProps(response, mapResponse));
		setSuspense(false);
		if(r.error) {
			setError(r.error);
		} else {
			setResult({...fillTemplatedObject(flatResult, key => args[key]), ...r});
		}
	}

	const postProcessError = e => {
		setSuspense(false);
		/*
		If we get an http error, we want to be able to remap it using mapResponse.
		This gives us the ablity to allow errors to be handled by presenting custom screens 
		rather than just displaying errors inline:
		*/
		if(flatError){
			setResult({error:e, ...flatError});
		} else if(mapResponse && (mapResponse.error || mapResponse['error:null'])) {
			setResult(fillTemplatedObject(mapProps({error:e.toString()}, mapResponse), key => args[key]));
		} else {
			setError({general:t && t.common('service.fetcherror', e.message)|| e.message});
		}
		
	}

	return !endpoint 
		? setError(new Error(`${serviceName}() requires an endpoint uri be set in config:${endpoint}`)) 
		: remoteFn 
			? postProcessResponse(remoteFn(url, options))
			: fetch(url, options)
				.then(response => response.json())
				.then(postProcessResponse)
				.catch(postProcessError);
}

const generateRemoteService = (id, {endpoint, method, headers, mapRequest, mapResponse, result}, flatResult, flatError, remoteFn) => {
	return (args, api, previousResults, t) => {
		remoteCall({
			serviceName:id,
			endpoint,
			args,
			method:method || 'POST',
			headers,
			mapRequest,
			mapResponse,
			flatResult,
			flatError,
			remoteFn,
			result
		}, api, previousResults, t);
	}
};

function applyRetry(retry, fn) {
	return (args,{ setResult, setError, ...api}, prevResults, t) => {		
		let polls = 0;
		const pollTimesMax = Math.abs(retry.expire/retry.frequency);
		const retryResult  = result => {
			if(retry.until && !checkCondition(retry.until, (path) => dlv(result, path))) {
				polls += 1;
				if(polls < pollTimesMax) {
					setTimeout(() => {
						fn(args, {...api, setResult:retryResult, setError:retryError}, prevResults, t);
					},
					retry.frequency);
				} else {
					setResult(result);
				}
			} else {
				setResult(result);
			}
		}

		const retryError = e => {
			polls += 1;
			if(polls < pollTimesMax) {
				setTimeout(() => {
					fn(args, {...api,  setResult:retryResult, setError:retryError}, prevResults, t);
				},
				retry.frequency);
			} else {
				setError(e);
			}
		
		}
		return fn(args, {...api,  setResult:retryResult, setError:retryError}, prevResults, t);
	}
}

/**
 * Hook for allowing services to be called using descriptors.
 * @param {Object|Array} services A descriptor of the services to make available to call.
 * @param {Array} previousResults An array of previous result data to make available to service calls for binding.
 * @param {Object} t A i18n instance that the services can use.
 */
export function useServices(services = [], previousResults, t, {remoteFn} = {}) {
	const serviceContext = React.useContext(ServiceContext) || {};
	const [ suspense, setSuspense ] = React.useState(false);
	const [ result, setResultCore ] = React.useState();
	const [ resultNotify, setResultNotifyCore ] = React.useState();
	const [ error, setErrorCore ] = React.useState();

	const setResultNotify = (id, r) => r && setResultNotifyCore({id:resultNotify ? resultNotify.id + 1 : 0, service:id, ...r});
	const setResult = (id, r) => debugMode && logResult(id, setResultCore)({...result, ...r}) || setResultCore({...result, ...r});
	const setError = (id, r) => debugMode && logError(id, setErrorCore)(r) || setErrorCore(r);
	const clearErrors = () => setErrorCore({});


	const generateServiceInstance = service => {
		const existingService = serviceContext[service.id];
		let fn = (((service.remote && service.remote.active !== false) && generateRemoteService(service.id, service.remote, service.result, service.error, remoteFn)) 
		|| existingService 
		|| service.fallback 
		|| stub({
			...{ suspense : service.suspense, result:service.result , error:fillTemplatedObject(service.error, key => inResults(previousResults, key))},
			custom:(!service.error) ? (data, {setResult}) => setResult(fillTemplatedObject(service.result, key => data[key] || inResults(previousResults, key)) || data) : null
		}));
		const filterDataByFields = data => {
			if(!service.fields && !Array.isArray(service.fields)) return data;
			return service.fields.reduce((acc, field) => {
				acc[field] = data[field];
				return acc;
			}, {});
		};

		if(service.retry)fn = applyRetry(service.retry, fn);

		const delayStart = service.delay ? (fn) => (...args) => setTimeout(() => fn.apply(null,args), 3000) : (fn) => fn;
		const serviceFn = delayStart(debugMode ? logRequest(service.id, fn) : fn);
		
		const idSetResult = r => {
			setResultNotify(service.id, fillTemplatedObject(service.resultNotify, key => r[key] || inResults(previousResults, key)));
			const finalResult = {_service:service.id, ...r};
			setResult(service.id, finalResult);
			return finalResult;
		};
		const idSetError = r => {
			setError(service.id, r);
			return r;
		};
		const call = data => {
			return new Promise((resolve, reject) => {
				serviceFn(
					fillTemplatedObject({...filterDataByFields(data), ...(service.args || {})}, key => result && dlv(result, key) || inResults(previousResults, key)), //eslint-disable-line no-mixed-operators 
					{
						setSuspense, 
						setResult:r => {
							resolve(idSetResult(r));
						}, 
						setError:e => {
							resolve({
								error:idSetError(e)
							});
						}
					}, previousResults, t
				)
			});
		};
		return {id:service.id, call};
	};

	const instances =  services.map(generateServiceInstance);

	const call = (data, id) => {
		if(instances && instances.length) {
			if(!id) {
				return instances[0].call(data);
			} else {
				const match = instances.filter(service => id === service.id);
				if(match && match.length){
					const serv = match[0];
					return serv.call(data);
				} else {
					generateServiceInstance({id}).call(data);
				}
			}
		} else if(id) {
			generateServiceInstance({id}).call(data);
		}
	}
	return [suspense, call, result, error, clearErrors, resultNotify];
}

/**
 * A Context provider for exposing custom service implementations to the useServices Hook. 
 * @param {} props.value An object containing an init() function that returns an object containing the named functions.
 */
export function ServiceProvider({services, i18n, config, ...props}) {
	const serviceContext = React.useContext(ServiceContext) || {};
	return <ServiceContext.Provider 
		value={{...serviceContext, ...(services && services({config, i18n:i18n}))}} 
		{...props}
		/>
}

export function useResult(result, resultFn, navigateTo) {
	React.useEffect(() => (result && resultFn) && resultFn(result, navigateTo), [result]); //eslint-disable-line react-hooks/exhaustive-deps
}

export function forService(results = [], service){
	return results.filter(result => result._service && result._service === service);
}

export function inResults(results = [], field){
	for(let i = results.length - 1; i >= 0; i--) {
		// `results` Array contains hierarchically merged data of various descriptor configs as `configData` object.
		// So we'll search in either `configData` OR other result sets.
		const searchIn = results[i].configData || results[i];
		const value = dlv(searchIn, field);
		if(value) return value;
	}
}