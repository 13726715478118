/** DBee: A simple abstract key-value store.
 *	@namespace {function} dbee
 *	@name dbee
 */

/** @namespace adapters
 *	@name adapters
 */

import { localStorageAdapter, sessionStorageAdapter } from './adapters/web-storage-adapters';
import memory from './adapters/memory';

const ADAPTERS = {
	localStorage: localStorageAdapter,
	sessionStorage: sessionStorageAdapter,
	memory,
	default: localStorageAdapter
};

/** A simple in-memory key-value store with persistence adapters.
 *	In addition to providing a sealed abstraction over `localStorage`,
 *	this has the advantage of queuing/deferring serialization & writes.
 *	@param {string} storageKey		A namespace key to use for storage.
 *	@param {Object} [options]
 *	@param {string} [options.adapter="localStorage"]	Which backing storage adapter to use, eg: `"memory"`
 *	@param {string} [options.saveDelay=1]	How long to delay the save in ms
 *	@returns {dbee} db
 *	@memberof dbee
 *	@static
 *	@private
 */
export default function dbee(storageKey, options={}) {
	let data, saveQueue;

	if (storageKey && typeof storageKey==='object') {
		[storageKey, options] = [options, storageKey];
	}

	let adapter = (ADAPTERS[options.adapter] || ADAPTERS.default)({ storageKey });

	if (adapter.test && !adapter.test()) {
		adapter = ADAPTERS.memory();
	}

	/** @private attempt to load data from the storage adapter */
	function load() {
		if (!data) {
			data = adapter.load() || {};
		}
		return data;
	}

	/** @private write out in-memory data to the storage adapter */
	function save() {
		clearTimeout(saveQueue);
		saveQueue = null;
		if (data) adapter.save(data);
	}

	/** @private Queue an asynchronous save() as soon as possible */
	function queueSave() {
		let { saveDelay=1 } = options;
		if (saveDelay===0) {
			save();
		}
		else if (!saveQueue) {
			saveQueue = setTimeout(save, saveDelay);
		}
	}

	return /** @lends dbee.prototype */ {

		/** Get a new db, restricted to a sub-namespace of the current db's namespace.
		 *	If you are passed a reference to a database but wish to work *within* it, use `ns()`.
		 *	@example
		 *	db('foo').ns('bar') // equivalent to:  db('foo.bar')
		 */
		ns(key) {
			return dbee(`${storageKey}.${key}`, options);
		},

		/** Retrieve the value for a given `key`.
		 *	@param {string} key		The key to look up. Keys are always treated as Strings.
		 *	@returns {any|undefined} value
		 *	@example
		 *	db('foo').get('bar')
		 */
		get(key) {
			load();
			return data[String(key)];
		},

		/** Set the `value` for a given `key`.
		 *	@param {string} key		The key to set a value for. Keys are always treated as Strings.
		 *	@param {any} value		A value to store. Must be JSON-serializable (ie, no cyclical references)
		 *	@returns {db} this
		 *	@example
		 *	db('foo').set('bar', 1).set('bar', 2)
		 */
		set(key, value) {
			load();
			data[String(key)] = value;
			queueSave();
			return this;
		},

		/** Get all the keys in the database
		 *	@return {string[]} an array of keys which are currently in the database
		 */
		keys() {
			load();
			return Object.keys(data || {});
		},

		/** Remove a value from database by its key
		 *	@param {string} key		The key to be removed from the database
		 *	@returns {db} this
		 */
		remove(key) {
			load();
			delete data[String(key)];
			queueSave();
			return this;
		},

		/** Remove all stored data.
		 *	@returns {db} this
		 *	@example
		 *	db('foo').set('a', 1).clear().get('a')===undefined
		 */
		clear() {
			data = {};
			queueSave();
			return this;
		}
	};
}

//export { adapters };
dbee.adapters = ADAPTERS;
