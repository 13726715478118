import React, { lazy } from 'react';

import TEMPLATES from './types/dynamic/templates';
import CUSTOM from './custom';

/**
 * Single source of truth for downloading different screens (entry points for a given route).
 * Lazy loaded components which get downloaded on-request reducing js chunk size since they get split by webpack.
 */
const SCREENS = {
	Auth: lazy(() => import(
		/* webpackChunkName: "auth-screen" */
		'./auth'
	)),
	DynamicScreen: lazy(() => import(
		/* webpackChunkName: "dyn-form-screen" */
		'./types/dynamic'
	)),
	...TEMPLATES,
	...CUSTOM
};

const ScreensContext = React.createContext();

export function ScreensProvider({ ...props }) {
	return <ScreensContext.Provider {...props} value={SCREENS} />;
}

export default function useScreens() {
	return React.useContext(ScreensContext);
}