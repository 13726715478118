/* 
eslint-disable no-mixed-operators
*/
import { isString, exists, isObject, isBoolean } from './index';
import { matchAll } from '../util';
import dlv from 'dlv';
import * as formatterFns from './formatters';

const templatePattern = '{{(.+?)}}';

/**
 * Examines potentialTemplate to see if it contains {{var}} template markup.
 * @param {String} potentialTemplate The string value to examine.
 */
export function isTemplate(potentialTemplate) {
	return isString(potentialTemplate) && potentialTemplate.search(templatePattern) > -1 || false;
} 

/**
 * Used to determine if potentialTemplate matches the {{var}} template format, but is expected 
 * to return the full value of the var reference and not the value as a string.
 * 
 * @param {String} potentialTemplate The string value to examine.
 */
export function isTemplatedValue(potentialTemplate) {
	return isString(potentialTemplate) && potentialTemplate.search(`^${templatePattern}$`) > -1 && matchAll(potentialTemplate, '{{').length === 1 || false;
} 

export function templateMatches(value) {
	return isString(value) ? [...matchAll(value, templatePattern)] : [];
} 

export function templateKeys(value) {
	return templateMatches(value).reduce((acc, [,propName]) => {
		acc.push(propName);
		return acc;
	}, []);
}

/**
 * Returns an object exposing all the elements of a 
 * template prop name assuming this syntax:
 * 
 * propPath|defaultValue?formatter,formatter2
 */
export function getPropPathElements(prop) {
	if(!prop || !isString(prop)) return {};
	const [formattersName, formattersString] = prop.split('?');
	const [defValName, defaultValue ='' ] = formattersName.split('|');
	const formatters = formattersString ? formattersString.split(',').map(formatterStr => ({name:formatterStr})) : [];
	return { name:defValName, defaultValue, formatters };
}

/**
 * Applies the requested formatters the supplied object
 * if the formatters exist.
 * @param {Object} value Any Object.
 * @param {Array} formatters An array of formatter descriptors to apply: [{name:'lowercase', arguments:[]}].
 */
export function applyFormatters(value, formatters = []) {
	return formatters.reduce((acc, formatter) => {
		if(formatterFns[formatter.name]) {
			return formatterFns[formatter.name](acc);
		}
		return acc;
	}, value);
}

export function stringFromTemplate(value, findFn, extraProps = {}) {
	return templateMatches(value).reduce((acc, [match, propPath]) => {
		const {name, defaultValue, formatters} = getPropPathElements(propPath);
		const findResult = findFn && findFn(name);
		
		const replaceWith = (findResult) ? findResult : dlv(extraProps, name) || defaultValue; 
		const replaceWithFormatted = replaceWith && applyFormatters(replaceWith, formatters);
		return (exists(replaceWithFormatted)) ? acc.replace(match, !isObject(replaceWithFormatted) ? replaceWithFormatted : '') : acc;
	}, value);
}

export function valueFromTemplate(value, findFn, extraProps = {}) {
	if (isBoolean(value)) return value;

	return templateMatches(value).reduce((acc, [, propName]) => {
		const {name, defaultValue, formatters} = getPropPathElements(propName);
		const doFind = (key) => findFn && findFn(key) || dlv(extraProps, key) || null;
		const replaceWith = doFind(name) || (defaultValue ? (doFind(defaultValue) || defaultValue) : null);

		if(exists(replaceWith)) {
			const replaceWithFormatted = applyFormatters(replaceWith, formatters);
			if(!exists(acc))return replaceWithFormatted;
			if(Array.isArray(acc)) {
				acc.push(replaceWithFormatted);
				return acc;
			}
			return [acc, replaceWithFormatted];
		}
		return acc;
	}, null);
}

export function fillTemplate(value, findFn, extraProps) {
	return isTemplatedValue(value) 
		? valueFromTemplate(value, findFn, extraProps) 
		: stringFromTemplate(value, findFn, extraProps);
}

export function fillTemplatedObject(obj, findFn, extraProps) {
	if(!obj || !findFn) return;
	if(isString(obj)) return isTemplatedValue(obj) ? valueFromTemplate(obj, findFn, extraProps) : stringFromTemplate(obj, findFn, extraProps);
	if(Array.isArray(obj)) return obj.map(o => fillTemplatedObject(o, findFn, extraProps));
	return Object.keys(obj).reduce((acc, key) => {
		if(isTemplate(obj[key])) {
			acc[key] = fillTemplate(obj[key], findFn, extraProps);
		} else {
			acc[key] = isObject(obj[key]) ? fillTemplatedObject(obj[key], findFn, extraProps) : obj[key];
		}
		return acc;
	}, {});
}