/* 
eslint-disable no-mixed-operators
*/
import { flatten } from 'flat';
import dlv from 'dlv';
import dset from 'dset';
import { isString } from ".";
import { getConfigResourcePath } from './client';

const opPatch = (source, objKey, data) => {
    dset(source, objKey, {
        ...dlv(source, objKey),
        ...data
    });
}

const performOverrideOp = (source, objKey, op) => {
    op.forEach(o => {
        switch(o['@type']) {
            case 'PATCH':
                opPatch(source, objKey, o['@data']);
                break;
            default:
                //no op..
                break;
        }
    });
};

/**
 * This method operates on `source` and `override` config (which extends/inherits source config) descriptors data,
 * based on instructions given in `override` config data.
 * 
 * @param {<Object>} source
 * @param {<Object>} confOverrides
 */
const overrideConfig = (sourceConf, confOverrides) => {
    let flatSrcConfs = flatten(sourceConf);
    confOverrides = confOverrides.filter(conf => conf['@lookup'] && conf['@match'] && conf['@op']);

    if (confOverrides.length) {
        confOverrides.forEach(conf => {
            const lookupRegExp = new RegExp(conf['@lookup']);

            const lookupObjKey = Object.keys(flatSrcConfs).filter(
                    k => k.match(lookupRegExp) // filter the keys based on lookup
                ).find(
                    k => flatSrcConfs[k] === conf['@match'] // find the exact key with obj[key] matching the conf[match]
                ).split('.').slice(0, -1).join('.'); // get the object path.

            if (lookupObjKey) {
                performOverrideOp(sourceConf, lookupObjKey, conf['@op']);

                // Update `flatsource` with latest `sourceConf` updates.
                flatSrcConfs = flatten(sourceConf);
            }
        });
    }

    return sourceConf;
};

const  CONFIG_FETCH_REQ_HEADERS = {
    method: 'GET',
    headers: {
        Accept:'application/json'
    }
};

function fetchOrObject(path) {
	return isString(path) 
		? fetch(path, CONFIG_FETCH_REQ_HEADERS).then(r => r.json()) 
		: Promise.resolve(path);
}

/**
 * Recursively download configurations based on path given and resolve the same.
 * 
 * @param `prevConfig` is used to save previously saved data.
 * @param {<String>} path 
 * @param {<Object>} prevConfig 
 */
export default function fetchConfig(path, prevConfig) {
    if (!path) {
		return Promise.resolve({});
    }

    return fetchOrObject(path)
        .then(config => {
            const { '@extends': dependencyConfPath, '@overrides': confOverrides } = config;

            if (dependencyConfPath) {
                return fetchConfig(getConfigResourcePath(dependencyConfPath), confOverrides);
            } else if(prevConfig) {
                return overrideConfig(config, prevConfig);
            } else {
				return config;
			}
        }).catch(e => {
            console.log(`Unable to download configuration file, reason: ${e}`);
            throw e;
		});
}